import React, { FC, ReactNode } from "react";
import styled from "styled-components";

interface ISidebarAppLayout {
  appMenu: ReactNode;
  breakpoint?: string;
  appMenuCollapsed?: boolean;
  children?: ReactNode;
}

const StyledSidebarAppLayout = styled.div`
  display: flex;
`;

const View = styled.div<{ $collapsed: boolean }>`
  flex: 1;
  width: ${props => (props.$collapsed ? `calc(100% - 76px);` : `calc(100% - 216px);`)};
`;

export const SidebarAppLayout: FC<ISidebarAppLayout> = ({
  appMenu,
  appMenuCollapsed,
  children,
  ...rest
}) => {
  return (
    <StyledSidebarAppLayout {...rest}>
      {appMenu}
      <View $collapsed={!!appMenuCollapsed}>{children}</View>
    </StyledSidebarAppLayout>
  );
};
